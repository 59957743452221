// Libs
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

// Pages
import Layout from '../components/Layout';
import SEO from '../components/seo';

// Components
import Hero from '../components/Hero';
import Features from '../components/Features';
import Journey from '../components/Journey';
import Strategy from '../components/Strategy';
import PreviewBlog from '../components/PreviewBlog';
import CanvasList from '../components/CanvasList';
import Security from '../components/Security';
import Feedbacks from '../components/Feedbacks';

export const IndexQuery = graphql`
  query {
    blog {
      posts (last: 3, locales: [en]) {
        id
        slug
        title
        coverImage (locales: [en]) {
          url
        }
        author
        authorPicture (locales: [en]) {
          url
        }
        date
      }
      feedbacks (last: 3, locales: [en]) {
        id
        author
        comment
        occupation
        authorPicture (locales: [en]) {
          url
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [widthSize, setWidthSize] = useState('');
  const [isOpenCanvas, setOpenCanvas] = useState(undefined);
  const [firstScroll, setFirstScroll] = useState(true);

  const hasWindow = typeof window !== 'undefined';
  const isMobile = widthSize <= 480;
  const language = 'en';

  const handleWidthSize = () => {
    const widthViewPort = document.documentElement.clientWidth;

    setWidthSize(widthViewPort);
  }

  useEffect(() => {
    if (hasWindow) {
      handleWidthSize();
      window.addEventListener('resize', handleWidthSize);

      return () => {
        window.removeEventListener('resize', handleWidthSize);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = () => {
    if (firstScroll) {
      setOpenCanvas(true);
      setFirstScroll(false);
    }
  }

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('scroll', handleScroll);

      return () => document.removeEventListener('scroll', handleScroll);
    }
  }, [firstScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout
      type='home'
      language={language}
      isMobile={isMobile}
      isOpenCanvas={isOpenCanvas}
      isBlue={data.blog.posts.length < 3}
    >
      <SEO
        lang='en-US'
        title='Strytegy | Free, intuitive and user-friendly digital workspace'
        ogTitle='Manage and Boost Team’s Work, Projects and Tasks | Strytegy'
        description='➤ Manage and Boost Team’s Work, Projects and Tasks 100% Online ✅ ➤ Easily organize workflows and ✚ Start using Strategy as your work management tool!'
        alternativeLang='pt-br'
        alternativeLink='https://www.strytegy.com/pt-br/'
        canonicalLink='https://www.strytegy.com/en-us/'
      />
      <Hero
        language={language}
        isMobile={isMobile}
      />
      <Features
        language={language}
        isMobile={isMobile}
      />
      <Journey
        language={language}
        isMobile={isMobile}
      />
      <Strategy
        language={language}
        isMobile={isMobile}
      />
      <Security 
        language={language}
        isMobile={isMobile}
      />
      <Feedbacks 
        language={language}
        isMobile={isMobile}
        feedbacksList={data.blog.feedbacks}
      />
      {data.blog.posts.length === 3 && <PreviewBlog
        language={language}
        isMobile={isMobile}
        blogList={data.blog.posts}
      />}
      {widthSize > 768 && <CanvasList
        language={language}
        isOpenCanvas={isOpenCanvas}
        setOpenCanvas={setOpenCanvas}
      />}
    </Layout>
  );
}

export default IndexPage;
